import React from 'react'

type Props = {
  subTitle: string
  title: string
  routes: item[]
}

type item = {
  to: string
  val: string
}

const Br: React.FC<Props> = ({ subTitle, title, routes }) => {
  return (
    <section
      className="breadcrumbs-custom bg-image context-dark"
      style={{ backgroundImage: 'url(/images/breadcrumbs-image-1.jpg)' }}
    >
      <div className="breadcrumbs-custom-inner">
        <div className="container breadcrumbs-custom-container">
          <div className="breadcrumbs-custom-main">
            <h6 className="breadcrumbs-custom-subtitle title-decorated">{subTitle}</h6>
            <h1 className="breadcrumbs-custom-title">{title}</h1>
          </div>
          <ul className="breadcrumbs-custom-path">
            {routes.map((val, index) => {
              return (
                <li key={index}>
                  <a className={routes.length === index + 1 ? 'active' : ''} href={val.to}>
                    {val.val}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Br
