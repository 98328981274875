import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {
  description?: string
  lang?: string
  meta?: (
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  )[]
  title: string
  metaImage?: {
    src: string
    width: number
    height: number
  }
  canonical: string
}

const SEO = ({
  canonical,
  description,
  lang = 'en',
  meta = [],
  metaImage = { src: '/images/logo.png', height: 273, width: 314 },
  title,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
            image
          }
        }
      }
    `,
  )
  if (metaImage && !metaImage.width) metaImage.width = 314
  if (metaImage && !metaImage.height) metaImage.height = 273
  const metaDescription = description || site.siteMetadata.description
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={canonical ? [{ rel: 'canonical', key: canonical, href: canonical }] : []}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `twitter:site`,
          content: `@${site.siteMetadata.author}`,
        },
        {
          name: `twitter:creator`,
          content: `@${site.siteMetadata.author}`,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: 'og:image',
                  content: image,
                },
                {
                  property: 'og:image:width',
                  content: metaImage.width,
                },
                {
                  property: 'og:image:height',
                  content: metaImage.height,
                },
                {
                  name: 'twitter:card',
                  content:
                    'Free Consulting ,Tax Return ,Bookkeeping -Payroll and Sale Tx Report ,Corporate Filling ,Notary Public',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content:
                    'Free Consulting ,Tax Return ,Bookkeeping -Payroll and Sale Tx Report ,Corporate Filling ,Notary Public',
                },
              ],
        )
        .concat(meta)}
    />
  )
}

export default SEO
