import Breadcrumbs from '@/Common/Breadcrumbs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import './style.css'
import { PrivacyPolicyChapter, PrivacyPolicyContent, PrivacyPolicyTableOfContent } from '@/types'
import { useI18next } from 'gatsby-plugin-react-i18next'

const LANG_NAMESPACE = 'privacy_policy'

const ROOT_CLASS = 'privacyPolicy'

const style = (...className: string[]) =>
  `${ROOT_CLASS}${className.length > 0 ? className.join(' ') : ''}`

const PrivacyPolicyPage = () => {
  const { t } = useTranslation(LANG_NAMESPACE)
  const { language } = useI18next(LANG_NAMESPACE)

  const tableChapters = useMemo((): PrivacyPolicyTableOfContent['chapters'] => {
    const chapters = t('tableOfContent.chapters', { returnObjects: true })

    if (Array.isArray(chapters)) return chapters
    return []
  }, [language])

  const chaptersInfo = useMemo((): PrivacyPolicyChapter[] => {
    const info = t('chapters', { returnObjects: true })

    if (Array.isArray(info)) return info
    return []
  }, [language])

  const initialTag = (info: PrivacyPolicyContent) => {
    return !!info?.initialTag ? <span className={style('-initialTag')}>{info.initialTag}</span> : ''
  }

  const description = (info: PrivacyPolicyContent) => {
    return !!info?.description ? (
      <span dangerouslySetInnerHTML={{ __html: info.description }} />
    ) : (
      ''
    )
  }

  const list = (info: PrivacyPolicyContent) => {
    return !info?.list ? (
      ''
    ) : (
      <ul className={style('__list')}>
        {info.list.map((el, i) => (
          <li key={i}>
            <span dangerouslySetInnerHTML={{ __html: el }} />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <>
      <Breadcrumbs
        subTitle={t('title')}
        title={t('title')}
        routes={[
          { to: '/', val: t('home', { ns: 'index' }) },
          { to: '#', val: t('title') },
        ]}
      />

      <div className={style()}>
        <h5 className={style('-lastUpdated')}>{t('lastUpdated')}</h5>

        <h3 className={style('-sectionTitle')}>{t('tableOfContent.title')}</h3>
        <ol className={style('__list', `${ROOT_CLASS}__orderList`)}>
          {tableChapters.map((chapter, i) => (
            <li key={i}>
              <a href={`#section${i}`}>{chapter}</a>
            </li>
          ))}
        </ol>

        {chaptersInfo.map((chapter, i) => (
          <React.Fragment key={i}>
            <h3
              id={`section${i}`}
              className={style('-sectionTitle')}
            >{`${i + 1}. ${chapter.title}`}</h3>

            {chapter.content.map((info, i) => (
              <div key={i} className={style('-paragraph')}>
                {initialTag(info)}
                {description(info)}
                {list(info)}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
export default PrivacyPolicyPage
