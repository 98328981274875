import React from 'react'
import Layout from '@/components/Layout'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import Header from '@/components/Header'
import PrivacyPolicyPage from '@/components/PrivacyPolicy'
import { graphql } from 'gatsby'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" canonical={`${useLocation().host}`} />
      <Header />
      <PrivacyPolicyPage />
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "footer", "privacy_policy"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
